define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './form',
  './createCustomer',
], (
  $, _, Backbone, SwappableView,
  FormView, CreateCustomerView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(FormView, {
      closeDef: options.closeDef,
    });
    this.setView('createCustomer', CreateCustomerView);
  },
}));
