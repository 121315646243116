define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/keypadPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi,
  KeypadView,
  KeyboardModel,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--keypad',

  regions: {
    keypad: '[data-region="keypad"]',
  },

  ui: {
    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  onRender() {
    this.renderKeypad();
  },

  renderKeypad() {
    const region = this.getRegion('keypad');
    const keypad = new KeypadView();
    region.show(keypad);
  },

  open(position, title, def) {
    title = title || Locale.translate('keypad');
    def = this.renderInFloatingRegion(def);

    this.setupPopup(position);

    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/keypadPopup',
      title,
    });

    this.openPopup();

    return def;
  },

  setupPopup(position) {
    this.$el.addClass(`dialog--${[position]}`);
    this.$el.css('box-shadow', '5px 5px 20px 0px black');
    setTimeout(() => {
      $('.mfp-bg').css('opacity', 0);
    });
  },

  onShow() {
    KeyboardModel.on('key:cancel', this.close, this);
    KeyboardModel.on('key:confirm', this.confirm, this);
  },

  onDestroy() {
    KeyboardModel.off('key:cancel', this.close, this);
    KeyboardModel.off('key:confirm', this.confirm, this);
    this.close();
  },

}));
