define([
  'jquery',
  'underscore',
  'backbone',
  './choice',
  'modules/shop.cash-register-retail/templates/popups/productAddonPopup/multipleChoice',
], (
  $, _, Backbone, ChoiceView, Template,
) => ChoiceView.extend({

  template: Template,

  events: {
    'change [type="checkbox"]': 'checkboxChanged',
  },

  checkboxChanged(ev) {
    const el = ev.currentTarget;
    const { value } = el;
    if (el.checked) {
      this.addSelection(value);
    } else {
      this.removeSelection(value);
    }
  },

}));
