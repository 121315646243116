define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/inputPopup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi,
  Keyboard,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--input',

  ui: {
    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
    input: '[data-ui="input"]',
    form: '[data-ui="form"]',
  },

  events: {
    'submit @ui.form': 'confirmClicked',

    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.confirm': 'confirmClicked',
    'touchend @ui.confirm': 'confirmClicked',
  },

  open(title, placeholder, def) {
    placeholder = placeholder || title;
    def = this.renderInFloatingRegion(def);

    this.ui.title.text(title);
    this.ui.input.attr('placeholder', placeholder);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/inputPopup',
      title,
      placeholder,
    });

    this.openPopup();

    this.startKeyboard();

    setTimeout(() => {
      this.ui.input.focus();
      this.ui.input.click();
    }, 100);

    return def;
  },

  confirm() {
    this.closeDef.resolve({
      input: this.ui.input.val(),
    });
    this.close();
  },

  close() {
    PopupView.prototype.close.call(this);
    this.stopKeyboard();
  },

  startKeyboard() {
    Keyboard.on('key:confirm', this.confirm, this);
    Keyboard.setViewWithMode(this.cid, Keyboard.MODE_INPUT_POPUP);
  },

  stopKeyboard() {
    Keyboard.off('key:confirm', this.confirm, this);
    Keyboard.setNeutralMode();
  },

}));
