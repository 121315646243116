define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/giftcardManagePopup/tableRow.hbs',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/views/inputs/giftCardInput',
], (
  $, _, Backbone, Template, DefaultShopConfiguration, GiftInputView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="delete"]': 'deleteClick',
  },

  deleteClick() {
    this.model.collection.remove(this.model);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.currency_iso3 = DefaultShopConfiguration.getCurrencyIso3();
    return data;
  },

  maybeRenderInput() {
    if (this.ui.pinInput.get(0)) {
      const region = this.getRegion('pinInput');
      const view = new GiftInputView({
        model: this.model,
        attributeName: 'pin',
      });
      region.show(view);
      if (!this.model.get('pin') && this.model.get('requiresPin')) {
        view.setFocus();
      }
    }
  },

}));
