define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/chooseOpenOrder/item.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  ui: {
    action: "[data-action='action']",
  },

  events: {
    click: 'elementClicked',
    'click @ui.action': 'elementClicked',
  },
  initialize({ currentOrder, hasIcl }) {
    this.currentOrder = currentOrder;
    this.hasIcl = hasIcl;
  },
  elementClicked() {
    this.triggerMethod('child:clicked', this.model);
  },

  serializeData() {
    if (this.currentOrder
        && this.currentOrder.get('id') === this.model.get('id')
    ) {
      return { currentOrder: true };
    }
    const data = this.model.toJSON();
    data.hasIcl = this.hasIcl;
    return data;
  },
}));
