define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/giftcardPopup/form.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'toastr',
  'modules/shop.cash-register-retail/models/keyboard',

  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/views/inputs/currencyInput',
  'modules/shop.cash-register-retail/views/inputs/giftCardInput',
  'modules/shop.cash-register-retail/views/inputs/customerSearchInput',

  'modules/admin/components/select2',
], (
  $, _, Backbone, PopupView, Template,
  Locale, CashRegisterApi, Toastr, KeyboardModel,
  KeypadView, CurrencyInput, GiftCardInput, CustomerSearchInput,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  ui: {
    search: '[data-ui="search"]',
    customer: '[data-ui=customer]',
  },

  events: {
    // Save
    'click [data-action="save"]': 'saveClicked',
    'touchend [data-action="save"]': 'saveClicked',

    // Create new customer
    'click [data-action="new-customer"]': 'newCustomer',
    'touchend [data-action="new-customer"]': 'newCustomer',
  },

  regions: {
    keypad: '[data-region="keypad"]',
    customer: '[data-region="customer"]',
    balance: '[data-region="balance"]',
    code: '[data-region="code"]',
  },

  className: 'dialog--hospitality-membercard',

  initialize(options) {
    this.model = new Backbone.Model({
      code: '',
      balance: '0.00',
      relation_data_id: null,
      relation_data_name: null,
    });
    this.title = options.title;
    this.closeDef = options.closeDef;
    this.customerModel = options.customerModel;
    this.attachCustomer = options.attachCustomer || false;
    this.createCustomer = options.createCustomer || false;

    KeyboardModel.scanPopupMode(this.cid);
  },

  serializeData() {
    return {
      attachCustomer: this.attachCustomer,
      createCustomer: this.createCustomer,
    };
  },

  newCustomer(ev) {
    if (this.createCustomer) {
      const el = ev.currentTarget;
      const isPrivate = el.dataset.private === 'true';
      this.triggerMethod('layout:swap', 'createCustomer', {
        is_private: isPrivate,
      });
    }
  },

  onRender() {
    this.renderKeypad();
    if (this.attachCustomer) {
      this.renderCustomerSearchInput();
    }
    this.renderBalance();
    this.renderCode();
  },

  onDestroy() {
    if (KeyboardModel.get('viewId') === this.cid) {
      KeyboardModel.setNeutralMode();
    }
  },

  renderKeypad() {
    const region = this.getRegion('keypad');
    const view = new KeypadView();
    region.show(view);
  },

  renderCustomerSearchInput() {
    const region = this.getRegion('customer');
    this.customerView = new CustomerSearchInput({
      placeholder: Locale.translate('type_to_search_for_a_customer'),
    });
    region.show(this.customerView);
    this.customerView.on('customer:selected', (model) => {
      this.model.set({
        relation_data_id: model.get('id'),
        relation_data_name: model.get('name'),
      });
      this.balanceView.setFocus();
    });
  },

  renderBalance() {
    const region = this.getRegion('balance');
    this.balanceView = new CurrencyInput({
      resetOnConfirmation: false,
      model: this.model,
      attributeName: 'balance',
    });
    region.show(this.balanceView);
    this.balanceView.on('view:confirm', () => {
      this.codeView.setFocus();
    });
  },

  renderCode() {
    const region = this.getRegion('code');
    this.codeView = new GiftCardInput({
      resetOnConfirmation: false,
      model: this.model,
      attributeName: 'code',
    });
    region.show(this.codeView);
    this.codeView.on('view:confirm', () => {
      // When the input is confirmed, change mode to scan_popup mode so it doesn't get scanned as a product after confirmation.
      KeyboardModel.scanPopupMode(this.cid);
    });
  },

  saveClicked: _.debounce(function () {
    if (this.isValid()) {
      this.closeDef.resolve({
        code: this.model.get('code'),
        balance: this.model.get('balance'),
        relation_data_id: this.model.get('relation_data_id'),
        relation_data_name: this.model.get('relation_data_name'),
      });
    }
  }, 25),

  isValid() {
    if (!this.model.has('code') || this.model.get('code').length <= 0) {
      Toastr.error(Locale.translate('gift_card_needs_to_be_scanned'));
      return false;
    }

    return true;
  },

}));
