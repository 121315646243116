define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/appendOrderItemsToOpenOrder.hbs',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/currency',

], (
  $, _, Backbone, PopupView, Template, Locale,
  CashRegisterApi, Currency,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--simple',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
    confirm: '[data-action="confirm"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.confirm': 'confirm',
    'touchend @ui.confirm': 'confirm',
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
    this.appendToOrder = options.appendToOrder;
    this.order = options.order;
  },
  open() {
    const def = this.renderInFloatingRegion();

    const title = Locale.translate('do_you_want_to_add_items_to_existing_open_order');
    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/appendOrderItemsToOpenOrder',
      appendToOrderId: this.appendToOrder.get('id'),
      order_items: this.order.get('order_items'),
    });

    this.openPopup();

    return def;
  },

  serializeData() {
    const newValue = Currency.Math.add(
      this.order.get('value_wt').toFixed(2),
      Currency.toCurrency(this.appendToOrder.get('value_wt').toFixed(2)),
    );
    return {
      count: this.order.get('item_no'),
      number: this.appendToOrder.get('number'),
      new_value: Currency.format(this.order.get('currency_iso3'), newValue),
    };
  },
}));
