define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/configurableSelectionPopup/list',
  './listItem',

  'modules/shop.cash-register-retail/components/productStock',
], (
  $, _, Backbone, Template, ItemView, ProductStock,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,
  childViewContainer: 'tbody',

  events: {
    'click [data-action="more"]': 'moreClicked',
  },

  ui: {
    more: '[data-ui="more"]',
  },

  childView: ItemView,

  childViewOptions(model) {
    return {
      model,
      resultDef: this.resultDef,
      showAttributeIds: this.showAttributeIds,
    };
  },

  initialize({ resultDef, productKind }) {
    this.resultDef = resultDef;
    this.showAttributeIds = productKind.configurable_attribute_ids || [];
  },

  moreClicked() {
    if (this.collection.canFetchNext()) {
      const self = this;
      this.triggerMethod('start:loader');
      this.collection.fetchNext()
        .always(() => {
          self.btnCheck();
          self.triggerMethod('stop:loader');
        });
    }
  },

  btnCheck() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  onShow() {
    this.btnCheck();
  },
  serializeData() {
    return {
      useStock: ProductStock.useStockValues(),
    };
  },
}));
