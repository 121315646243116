define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/printMultiplePriceTagsPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/views/products/catalog/printMultiplePriceTags/swappable',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi, PrintMultiplePriceTagsSwappableView,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
  },

  regions: {
    content: '[data-region="content"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  onRender() {
    const view = new PrintMultiplePriceTagsSwappableView();
    const region = this.getRegion('content');
    region.show(view);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/printMultiplePriceTagsPopup',
    });

    this.openPopup();

    return def;
  },

}));
