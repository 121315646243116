define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/usedGoodPopup.hbs',

  'modules/shop.cash-register-retail/views/products/description/serialsInput.js',
  'modules/upx/behaviors/validation',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/views/keypads/main',
  'upx.modules/ShopModule/models/ShopFlatProduct',
  'modules/shop.cash-register-retail/views/inputs/ppuInput.js',
  'modules/shop.cash-register-retail/components/feature.js',

], (
  $, _, Backbone, PopupView, Template,
  SerialsInputView, Validation,
  Locale, KeyboardModel, PercentageKeypad, ShopFlatProductModel, PpuInputView, Feature,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--used-goods',

  ui: {
    name: '[name="name"]',
    description: '[name="description"]',
    close: '[data-action="close"]',
    confirm: '[data-action="confirm"]',
    serialContainer: '[data-ui="serial-container"]',
  },

  events: {
    'click  @ui.close': 'closeClicked',
    'click @ui.confirm': 'confirmClicked',
    'change  @ui.name': 'nameChanged',
    'change  @ui.description': 'descriptionChanged',
  },

  regions: {
    keypad: '[data-region="keypad"]',
    serialInput: '[data-region="serials-input"]',
    ppuInput: '[data-region="ppu-input"]',
  },

  behaviors: {
    Validation: {
      behaviorClass: Validation,
      method: 'save',
    },
  },
  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
    const { shopProductModel, taxRateLabel } = options;
    this.model = new Backbone.DeepModel({
      name: Locale.translate('used_goods'),
      description: '',
      ppu_wt: '0.01',
      currency_iso3: shopProductModel.get('product_price.currency_iso3'),
      taxRateLabel,
    });
    this.model.mapping = {
      save: {
        name: {
          required: true,
        },
        description: {
          required: false,
        },
        ppu_wt: {
          required: true,
        },
      },
    };
  },

  nameChanged() {
    this.model.set('name', this.ui.name.val());
  },
  descriptionChanged() {
    this.model.set('description', this.ui.description.val());
  },

  renderKeyboard() {
    const region = this.getRegion('keypad');
    const view = new PercentageKeypad();
    region.show(view);
  },

  hasSerialNumbers() {
    return Feature.isProductSerialFeatureEnabled();
  },

  renderSerialInput() {
    const region = this.getRegion('serialInput');
    const view = new SerialsInputView({
      model: this.model,
    });
    region.show(view);
    this.serialsView = view;
  },

  confirm() {
    if (!this.model.isValid(true)) {
      return;
    }
    // get model
    if (this.serialsView) {
      this.model.set('serial_nos', this.serialsView.getValue());
    } else {
      this.model.set('serial_nos', '');
    }

    this.closeDef.resolve(this.model.toJSON());
    PopupView.prototype.confirm.call(this);
  },
  renderPpuInput() {
    const region = this.getRegion('ppuInput');
    const view = new PpuInputView({
      model: this.model,
    });
    region.show(view);
  },

  onRender() {
    this.renderKeyboard();
    if (this.hasSerialNumbers()) {
      this.renderSerialInput();
    } else {
      this.ui.serialContainer.hide();
    }
    this.renderPpuInput();
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    this.logOpenAction(
      'modules/shop.cash-register-retail/views/popups/usedGoodPopup',
    );
    this.openPopup();
    KeyboardModel.popupMode(this.cid);
    return def;
  },

  serializeData() {
    return _.extend({}, this.model.toJSON(), {
      isSerialsEnabled: this.hasSerialNumbers(),
    });
  },

}));
