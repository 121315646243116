define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/categoryPopup.hbs',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/models/keyboard',

  'modules/shop.cash-register-retail/views/products/blocks/products/collection',
  'modules/shop.cash-register-retail/components/productCategoryCache',
], (
  $, _, Backbone, PopupView, Template, Locale,
  CashRegisterApi, Loader, KeyboardModel,
  ProductCollectionView, ProductCategoryCache,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--category',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  ui: {
    close: '[data-action="close"]',
    success: '[data-action="success"]',
    title: '[data-ui="title"]',
    code: '[data-ui="code"]',
    accept: '[data-ui="accept"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.accept': 'success',
    'touchend @ui.accept': 'success',
  },

  regions: {
    products: '[data-region=products]',
  },

  renderContent(category_id, onlyActive) {
    const def = this.loader.startLoader();
    const region = this.getRegion('products');

    const productsDef = onlyActive
      ? ProductCategoryCache.getActiveProductsByCategory(category_id)
      : ProductCategoryCache.getProductsByCategory(category_id);

    productsDef.then((product_collection) => {
      const view = new ProductCollectionView({
        collection: product_collection,
        categoryId: category_id,
        type: 'full',
        searchModel: new Backbone.Model({
          value: '',
        }),
      });
      view.on('child:clicked', (model) => {
        this.close();
        this.triggerMethod('child:clicked', model);
      });
      region.show(view);
      def.resolve();
    }, def.reject);
  },

  open(def, category_id, title, onlyActiveProducts = false) {
    def = this.renderInFloatingRegion(def);
    KeyboardModel.popupMode(this.cid);

    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/categoryPopup',
      title,
      category_id,
    });

    this.openPopup();

    this.renderContent(category_id, onlyActiveProducts);

    return def;
  },

  onDestroy() {
    if (KeyboardModel.get('viewId') === this.cid) {
      KeyboardModel.setNeutralMode();
    }
    this.close();
  },

}));
