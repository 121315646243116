define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/orderDetailsPopup.hbs',

  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/views/products/list/layout',
  'modules/shop.cash-register-retail/views/popups/orderDetailsPopup/payments',
  'modules/shop.cash-register-retail/views/popups/orderDetailsPopup/invoices',
  'modules/shop.cash-register-retail/views/popups/messagePopup',

  'modules/admin/behaviors/loader',
  'toastr',
  'modules/common/components/locale',
  'modules/common/components/currency',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/feature',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/events/app/fullScreenLoader',

  'modules/shop.cash-register-retail/models/keyboard',
  'upx.modules/ShopModule/models/Order',
  'modules/common/collections/DeepModelCollection',
  'modules/shop.cash-register-retail/collections/orderItem',
  'modules/shop.cash-register-retail/collections/TaxRate',

  'upx.modules/ShopModule/collections/OrderPayment',
  'upx.modules/ShopModule/collections/OrderInvoice',
  'upx.modules/PaymentModule/collections/Payment',
  'upx.modules/ProductsModule/collections/Product',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/components/mode',
  'modules/shop.cash-register-retail/components/order',
  'modules/shop.cash-register-retail/components/payment',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
], (
  $, _, Backbone, PopupView, Template,
  KeypadPercentageView, ProductListView, PaymentListView, InvoicesListView, MessagePopup,
  Loader, Toastr, Locale, Currency, Upx, Feature,
  PrintingComponent, FullScreenLoaderEvent,
  KeyboardModel, OrderModel, DeepModelCollection, OrderItemCollection, TaxRateCollection,
  OrderPaymentCollection, OrderInvoiceCollection, PaymentCollection, ProductCollection,
  CashRegisterApi, Mode, Order, Payment, HistoryBreadcrumb, ReceiptPrinterModel,
) => PopupView.extend({
  template: Template,

  className: 'dialog return-products-popup',

  ui: {
    close: '[data-action="close"]',
    message: '[data-ui="message"]',
    title: '[data-ui="title"]',
    checkout: '[data-action="checkout"]',
    printReceipt: '[data-action="print-receipt"]',
    viewCustomer: '[data-action="view-customer"]',
    syncStatus: '[data-ui="sync-status"]',
    syncButton: '[data-ui="sync-button"]',
    createInvoiceButton: '[data-action="create-invoice"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
    'click @ui.checkout': 'checkoutClicked',
    'click @ui.printReceipt': 'printReceiptClicked',
    'click @ui.viewCustomer': 'viewCustomerClicked',
    'click @ui.syncButton': 'forceSync',
    'click @ui.createInvoiceButton': 'createInvoiceClicked',
  },

  regions: {
    'payment-list': '[data-region="payment-list"]',
    'product-list': '[data-region="product-list"]',
    'invoice-list': '[data-region="invoice-list"]',
    keypad: '[data-region="keypad"]',
    popup: '[data-region="popup"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
    this.refundDef = options.refundDef;
    this.keyboardModel = KeyboardModel;
    this.isInvoiceFeatureAvailable = Feature.getFeatureActiveByAppName(
      Feature.APP_NAME_CORE_BILLING_MODULE_INVOICING,
    );
  },

  onShow() {
    this.keyboardModel.on('change:mode', this.checkButton, this);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/orderDetailsPopup',
    });

    this.openPopup();

    this.keyboardModel.returnQuantityMode(this.cid);

    return def;
  },

  checkoutClicked() {
    const collection = new DeepModelCollection();
    const orderCollection = this.orderItemCollection.clone();
    orderCollection.each((model) => {
      let return_quantity = model.get('return_quantity');
      return_quantity = -Math.abs(return_quantity);
      model.set('quantity', return_quantity);
      if (return_quantity < 0) {
        model.unset('serial_nos');
        model.set('to_be_shipped_quantity', return_quantity);
        model.set('unfulfilled_quantity', return_quantity);
        collection.add(model);
      }
    });

    this.refundDef.resolve(collection);
    this.close();
  },

  printReceiptClicked() {
    const def = this.loader.startLoader('loader-print');

    PrintingComponent.printReceipt(this.model, { is_reprint: true })
      .then(def.resolve, def.reject);
  },
  viewCustomerClicked() {
    this.close();
    HistoryBreadcrumb.goto(`customers/details/${this.model.get('relation_data_id')}`);
  },
  createInvoiceClicked() {
    const def = this.loader.startLoader('list-payments-products');

    def.then(
      () => {
        Toastr.success(Locale.translate('created_new_invoice'));
      }, () => {
        Toastr.error(Locale.translate('failed_to_create_invoice'));
      },
    );
    const orderInvoiceModel = Payment.buildInvoiceForOrder(this.model);
    orderInvoiceModel.save()
      .then(
        () => {
          const orderDependentParams = this.getOrderDependedParams();
          const orderInvoiceCollection = new OrderInvoiceCollection();
          orderInvoiceCollection.fetch({ params: orderDependentParams }).then(
            () => {
              this.renderInvoices(orderInvoiceCollection);
              def.resolve();
            },
            def.reject,
          );
        },
        def.reject,
      );
  },

  onRender() {
    this.ui.syncButton.hide();
    this.renderLists();
    this.renderKeypad();
  },

  renderInvoices(orderInvoiceCollection) {
    if (orderInvoiceCollection.length > 0) {
      this.ui.createInvoiceButton.hide();
      const invoices = new InvoicesListView({
        collection: orderInvoiceCollection,
        onCloseClicked: () => this.closeClicked(),
      });
      this.getRegion('invoice-list').show(invoices);
    } else {
      this.ui.createInvoiceButton.show();
    }
  },
  getOrderDependedParams() {
    const orderDependentParams = {
      start: 0,
      limit: 0,
      filters: [{
        name: 'order_id__=',
        val: this.model.get('id'),
      }],
    };
    return orderDependentParams;
  },
  async renderLists() {
    const def = this.loader.startLoader('list-payments-products');
    const orderId = this.model.get('id');

    this.orderItemCollection = new OrderItemCollection(this.model.get('order_items'));
    // Adding missing currency_iso3 to the order items.
    this.orderItemCollection.each((orderItemModel) => {
      orderItemModel.set('currency_iso3', this.model.get('currency_iso3'));
    });
    const productRegion = this.getRegion('product-list');
    const view = new ProductListView({
      keyboardModel: this.keyboardModel,
      columns: [
        'quantity',
        'description',
        'ppu',
        'price',
        'return_quantity',
      ],
      editable_columns: this.hasAnyIclOrderItem() ? [] : [
        'return_quantity',
      ],
      collection: this.orderItemCollection,
    });
    productRegion.show(view);

    // Products are fetched for selection later.
    const product_ids = _.filter(
      _.unique(this.orderItemCollection.pluck('product_id')),
      (v) => !!v,
    );
    this.productCollection = new ProductCollection();
    const productParams = {
      start: 0,
      limit: 0,
      filters: [{
        name: 'id__in_list',
        multi_val: product_ids,
      }],
    };
    const orderDependentParams = this.getOrderDependedParams();
    const orderPaymentCollection = new OrderPaymentCollection();
    const orderInvoiceCollection = new OrderInvoiceCollection();
    const paymentCollection = new PaymentCollection();
    const needsSync = await Order.isOrderSynchronizingToBackend(orderId);
    let paymentDef = new $.Deferred();
    if (needsSync) {
      this.renderPaymentSyncStatus();
      const paymentIds = await Payment.getOrderSynchronizingPaymentIds(orderId);
      if (paymentIds.length > 0) {
        paymentDef = paymentCollection.fetch({
          params: {
            start: 0,
            limit: 0,
            filters: [{
              name: 'id__in_list',
              multi_val: paymentIds,
            }],
          },
        });
      } else {
        paymentDef.resolve();
      }
    } else {
      this.renderPaymentSyncStatus(this.model.get('is_paid'));
      paymentDef.resolve();
    }
    $.when(
      this.productCollection.fetch({ params: productParams }),
      orderPaymentCollection.fetch({ params: orderDependentParams }),
      orderInvoiceCollection.fetch({ params: orderDependentParams }),
      paymentDef,
    ).then(() => {
      // Region only needs to be used if it is not destroyed
      if (!this.isDestroyed) {
        const paymentRegion = this.getRegion('payment-list');
        let payments = _.extend(
          [],
          orderPaymentCollection.pluck('payment'),
          paymentCollection.toJSON(),
        );
        payments = payments.filter((payment) => !payment.is_virtual);

        const paymentView = new PaymentListView({
          payments,
        });
        paymentRegion.show(paymentView);
        if (this.isInvoiceFeatureAvailable) {
          this.renderInvoices(orderInvoiceCollection);
        }
      }
      def.resolve();
    }, def.reject);
  },

  renderPaymentSyncStatus(status) {
    const ico = $('<i class="fas"></i>');
    const { syncStatus } = this.ui;
    syncStatus.empty();
    syncStatus.append(ico);
    syncStatus.append(' ');
    if (typeof status === 'boolean') {
      if (status) {
        ico.addClass('fa-check');
        syncStatus.append(Locale.translate('paid'));
        syncStatus.addClass('text-success');
      } else {
        ico.addClass('fa-times');
        syncStatus.append(Locale.translate('not_paid'));
        syncStatus.addClass('text-danger');
      }
    } else {
      ico.addClass('fa-sync text-warning');
      syncStatus.append(Locale.translate('an_error_occurred'));
      syncStatus.addClass('text-warning');
      this.ui.syncButton.show();
    }
  },

  forceSync: _.debounce(async function () {
    const orderId = this.model.get('id');
    const { syncButton } = this.ui;
    const icon = this.ui.syncStatus.find('i');
    syncButton.prop('disabled', true);
    icon.addClass('fa-spin');

    try {
      const results = await Order.runOrderSync(orderId);
      let needsSync = false;
      results.forEach((result) => {
        if (!result.success) {
          needsSync = true;
          console.error(result.error);
        }
      });
      if (!needsSync) {
        // all success
        this.model.fetch().then(
          () => {
            this.renderPaymentSyncStatus(this.model.get('is_paid'));
            syncButton.hide();
            this.model.trigger('renderIcon');
          },
          (error) => {
            console.error(error);
            const view = new MessagePopup();
            view.open(Locale.translate('failed_to_get_order_after_sync'));
          },
        );
      } else {
        const view = new MessagePopup();
        view.open(Locale.translate('failed_to_sync_the_order'));
      }
    } catch (e) {
      syncButton.prop('disabled', false);
      icon.removeClass('fa-spin');
    }
  }, 100),

  renderKeypad() {
    const region = this.getRegion('keypad');
    const view = new KeypadPercentageView({
      model: this.keyboardModel,
    });
    region.show(view);
  },

  close() {
    this.stopListening();
    KeyboardModel.resetMode();
    PopupView.prototype.close.apply(this);
  },

  isCheckButtonEnabled() {
    if (!this.keyboardModel.isModeWithConfirmation()) {
      let hasProducts = false;
      if (this.orderItemCollection) {
        // not loaded
        this.orderItemCollection.each((model) => {
          if (model.get('return_quantity') > 0) {
            hasProducts = true;
            return false;// break loop
          }
        });
      }
      return hasProducts;
    }
    return false;
  },

  checkButton() {
    if (!_.isString(this.ui.checkout) && !this.disableRefund()) {
      this.ui.checkout.attr('disabled', !this.isCheckButtonEnabled());
    }
  },

  onDestroy() {
    this.keyboardModel.off('change:mode', this.checkButton, this);
    this.close();
  },

  hasAnyIclOrderItem() {
    const orderItems = this.model.get('order_items');
    const intraTaxRateId = TaxRateCollection.getByAliasAndCountry('special_community_intra', 'EU').get('id');
    const intraServiceTaxRateId = TaxRateCollection.getByAliasAndCountry('special_community_intra_service', 'EU').get('id');
    return !!_.findWhere(orderItems, { tax_rate_id: intraTaxRateId }) || !!_.findWhere(orderItems, { tax_rate_id: intraServiceTaxRateId });
  },

  disableRefund() {
    return !this.model.get('is_paid') && this.model.get('invoiced_price_wt') <= 0;
  },

  serializeData() {
    return {
      is_anonymous: this.model.get('is_anonymous'),
      relation_data: this.model.get('relation_data'),
      hasAnyIclOrderItem: this.hasAnyIclOrderItem(),
      number: this.model.get('number'),
      disableRefund: this.disableRefund(),
      has_receipt_printer: ReceiptPrinterModel.isWantedType(),
      isInvoiceFeatureAvailable: this.isInvoiceFeatureAvailable,
    };
  },

}));
