define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/treatments/layout.hbs',

  './categories',

  'modules/shop.cash-register-retail/collections/appointmentProduct',
  'modules/shop.cash-register-retail/collections/appointmentProductCategory',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  CategoriesView,
  AppointmentProductCollection, AppointmentProductCategoryCollection,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'treatments',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize(options) {
    this.appointmentModel = options.appointmentModel;
    this.searchModel = new Backbone.Model({
      query: '',
    });
  },

  events: {
    'keyup [data-action=search]': 'searchChanged',
  },

  searchChanged(ev) {
    const el = ev.currentTarget;
    const { value } = el;
    this.searchModel.set('query', value.trim());
  },

  regions: {
    categories: '[data-region=categories]',
  },

  onRender() {
    const region = this.getRegion('categories');
    const view = new CategoriesView({
      searchModel: this.searchModel,
      appointmentModel: this.appointmentModel,
    });
    region.show(view);
  },

  onShow() {
    const def = this.loader.startLoader('treatments');

    def.then(() => {
      if (!this.isDestroyed) {
        this.render();
      }
    });

    $.when(
      AppointmentProductCollection.load(),
      AppointmentProductCategoryCollection.load(),
    ).then(def.resolve, def.reject);
  },

}));
