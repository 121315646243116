define([
  'jquery',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/repairLogsPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'toastr',

  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/views/popups/repairLogsPopup/collection',

  'upx.modules/ShopModule/collections/RepairMessage',
], (
  $, Backbone, PopupView, Template, Locale, CashRegisterApi, Toastr,
  Loader,
  RepairMessagesCollectionView,
  RepairMessagesCollection,

) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  regions: {
    messages: '[data-region="messages"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ repair_id }) {
    this.repairId = repair_id;

    this.repairMessagesCollection = new RepairMessagesCollection();
    this.repairMessagesCollectionView = new RepairMessagesCollectionView({
      collection: this.repairMessagesCollection,
    });

    this.on('loader:start', function (def, name) {
      this.loader.showLoader(name);
      def.always(() => {
        this.loader.hideLoader(name);
      });
    });
  },

  fetchAllRepairMessages(def) {
    this.triggerMethod('loader:start', def, 'logs');
    this.repairMessagesCollection.fetchAll({
      params: {
        start: 0,
        limit: 100,
        sort: [{
          name: 'id',
          dir: 'desc',
        }],
        filters: [{
          name: 'repair_id__=',
          val: this.repairId,
        }],
      },
    }).then(def.resolve, ({ error }) => {
      Toastr.error(error, Locale.translate('repair_logs'));
      def.reject();
    });
  },

  renderMessages() {
    const self = this;

    const def = self.loader.startLoader();
    const region = this.getRegion('messages');
    region.on('show', () => {
      self.fetchAllRepairMessages(def);
    });
    region.show(this.repairMessagesCollectionView);
  },

  onRender() {
    this.renderMessages();
  },

  open(title, def) {
    def = this.renderInFloatingRegion(def);

    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/repairLogsPopup',
      title,
    });

    this.openPopup();

    return def;
  },

}));
