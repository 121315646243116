define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/ccvUnknownTransactionResultPopup.hbs',
  'modules/common/components/locale',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, PopupView, Template, Locale,
  Loader, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--ccv-transaction-result',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    // close
    'click [data-action=close]': 'closeClicked',
    'touchend [data-action=close]': 'closeClicked',
  },

  renderContent() {
    // const def = this.loader.startLoader();
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/ccvUnknownTransactionResultPopup',
    });

    this.openPopup();

    this.renderContent();

    return def;
  },

  onDestroy() {
    this.close();
  },

}));
