define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup.hbs',

  'modules/shop.cash-register-retail/models/employeeCalendarItems',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/moment',

  './calendarEventPopup/swappable',
], (
  $, _, Backbone, PopupView, Template,
  EmployeeCalendarItems,
  CashRegisterApi, Moment,
  SwappableView,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--calendar-event',

  events: {
    // close
    'click [data-action=close]': 'closeClicked',
    'touchend [data-action=close]': 'closeClicked',
  },

  closeClicked: _.debounce(function () {
    this.viewDeferred.reject();
  }, 25),

  initialize(options) {
    this.model = new EmployeeCalendarItems();
    if (options && 'model' in options) {
      this.model = options.model.clone();
    }

    // Set start date
    if (options && 'date_start' in options) {
      this.model.set({
        'item.date_start': new Moment(options.date_start).format(),
        'item.date_end': new Moment(options.date_start).format(),
      });
    }

    // Set all day
    if (options && 'calendar_id' in options) {
      this.model.set('item.calendar_id', options.calendar_id);
    }
  },

  open() {
    this.openPopup();
    this.viewDeferred = new $.Deferred();

    // Close modal when viewDeferred is rejected or resolved.
    this.viewDeferred.always(() => {
      $.magnificPopup.close();
    });

    this.renderView();

    return this.viewDeferred;
  },

  openPopup() {
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/calendarEventPopup',
      initialModel: this.model.toJSON(),
    });

    $.magnificPopup.open({
      items: [{
        src: this.$el.get(0),
        type: 'inline',
      }],
      removalDelay: 0,
      mainClass: 'mfp-fade',
      modal: true,
    });
  },

  onDestroy() {
    this.viewDeferred.reject();
  },

  regions: {
    view: '[data-region="view"]',
  },

  renderView() {
    const region = this.getRegion('view');
    const view = new SwappableView({
      model: this.model,
      viewDeferred: this.viewDeferred,
    });
    region.show(view);
  },

}));
