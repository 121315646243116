define([
  'jquery',
  'underscore',
  'backbone',

  './category',
  './noCategory',

  'modules/shop.cash-register-retail/collections/appointmentProduct',
  'modules/shop.cash-register-retail/collections/appointmentProductCategory',

  'modules/common/components/locale',
], (
  $, _, Backbone,
  CategoryView, NoCategoryView,
  AppointmentProductCollection, AppointmentProductCategoryCollection,
  Locale,
) => Backbone.Marionette.CollectionView.extend({

  childView: CategoryView,

  emptyView: NoCategoryView,

  childViewOptions() {
    return {
      searchModel: this.searchModel,
      appointmentModel: this.appointmentModel,
    };
  },

  viewComparator: 'id',

  /**
		 * Looping over the product items to only display used categories.
		 */
  initializeCollection() {
    AppointmentProductCollection.each((model) => {
      const categories = model.get('flat_product.categories');
      if (categories) {
        categories.forEach((item) => {
          if (
            // Check if the items is published
            item.published
							// Check if the items is a product category. and not a label.
							&& !!AppointmentProductCategoryCollection.get(item.id)
          ) {
            this.collection.add(item);
          }
        });
      } else
      // Check if the other category was already added
      if (!this.collection.findWhere({ alias: 'other' })) {
        this.collection.add({
          title: Locale.translate('other'),
          alias: 'other',
        });
      }
    });
  },

  initialize(options) {
    this.appointmentModel = options.appointmentModel;
    this.searchModel = options.searchModel;
    this.collection = new Backbone.Collection();
    this.initializeCollection();
  },

}));
