define([
  'jquery',
  'underscore',
  'backbone',
  './product',

  'modules/shop.cash-register-retail/collections/appointmentProduct',
], (
  $, _, Backbone, ProductView,
  AppointmentProductCollection,
) => Backbone.Marionette.CollectionView.extend({

  childView: ProductView,

  childViewOptions() {
    return {
      appointmentModel: this.appointmentModel,
    };
  },

  initialize(options) {
    this.appointmentModel = options.appointmentModel;
    this.searchModel = options.searchModel;
    this.categoryModel = options.categoryModel;
    this.collection = AppointmentProductCollection;
  },

  filter(child, index, collection) {
    let query = this.searchModel.get('query');

    // categoryMatch
    let categoryMatch = false;
    const wantedCategoryId = this.categoryModel.get('id');
    const categories = child.get('flat_product.categories');
    if (categories) {
      categories.forEach((item) => {
        if (!categoryMatch && item.id === wantedCategoryId) {
          categoryMatch = true;
        }
      });
    } else if (_.isUndefined(wantedCategoryId)) {
      categoryMatch = true;
    }

    // Is searching;
    if (query !== '') {
      const title = child.get('flat_product.title').toLowerCase();
      query = query.toLowerCase();
      return categoryMatch && title.indexOf(query) !== -1;
    }
    // else return just category match

    return categoryMatch;
  },

}));
