define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/datatables/layout',
  'modules/shop.cash-register-retail/views/datatables/defaultCellViews/dateTimeCell',

  'upx.modules/RelationsModule/collections/DataNote',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/datatables/DefaultColumnDefinitionModel',

  'modules/common/components/locale',
], (
  $, _, Backbone, TableView, DateTimeCell,
  DataNoteCollection,
  SelectedCustomer, DefaultColumnDefinitionModel,
  Locale,
) => TableView.extend({

  initializeCollection() {
    this.collection = new DataNoteCollection();
  },

  hasSearchField() {
    return false;
  },

  getFilters() {
    return [{
      name: 'about/id__=',
      val: SelectedCustomer.get('id'),
    }];
  },

  initializeColumnDefinitionCollection() {
    this.columnDefinitionCollection.add(new DefaultColumnDefinitionModel({
      name: Locale.translate('notes'),
      path: 'title',
      sortName: 'title',
      bodyCellRenderIsSafe: true,
      bodyCellRenderFn(title, model) {
        title = _.escape(title);
        const description = _.escape(model.get('note'));
        const preview = description.length > 1000 ? `${description.substring(0, 1000).trim()}...` : description;

        return `<b>${title}</b> | <span class="ws-pre-wrap">${preview}</span>`;
      },
    }));

    this.columnDefinitionCollection.add(new DefaultColumnDefinitionModel({
      name: Locale.translate('created_by'),
      path: 'relation_data.name',
      sortName: 'relation_data/name',
      headerCellClassName: 'cell-sm',
    }));

    this.columnDefinitionCollection.add(new DefaultColumnDefinitionModel({
      name: Locale.translate('created_on'),
      path: 'on_date',
      sortName: 'on_date',
      sortDir: 'desc',
      headerCellClassName: 'cell-sm',
      bodyCellCls: DateTimeCell,
    }));
  },

}));
