define([
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/components/repair.js',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/templates/popups/repairLogsPopup/item.hbs',
], (
  _, Backbone, Repairs, Locale, Template,
) => Backbone.Marionette.ItemView.extend({

  template: Template,

  formatExtra(extra) {
    let text = '';

    if (!extra) {
      return text;
    }

    if (!extra.old_status) {
      text = Locale.translate('repair_created');
    } else {
      text = `${Repairs.getStatusTitle(extra.old_status)} > ${Repairs.getStatusTitle(extra.new_status)}`;
    }

    return text;
  },

  serializeData() {
    const data = this.model.toJSON();
    const additionalData = {
      is_email_type: data.repair_message_type.alias === 'EmailNotice',
      formatted_extra: this.formatExtra(data.extra),
    };
    return {
      ...data,
      ...additionalData,
    };
  },
}));
