define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/fromToAmountPopup',

  'modules/shop.cash-register-retail/views/inputs/currencyInput',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, PopupView, Template,
  CurrencyInput, KeypadView,
  CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--from-to-amount',

  events: {
    'click [data-action="close"]': 'closeClicked',
    'touchend [data-action="close"]': 'closeClicked',

    'click [data-action="apply"]': 'applyClicked',
    'touchend [data-action="apply"]': 'applyClicked',
  },

  regions: {
    keypad: '[data-region=keypad]',
    first: '[data-region="first"]',
    second: '[data-region="second"]',
  },

  onRender() {
    this.renderFirst();
    this.renderSecond();
    this.renderKeyboard();
  },

  renderFirst() {
    const region = this.getRegion('first');
    this.firstView = new CurrencyInput();
    region.show(this.firstView);
  },

  renderSecond() {
    const region = this.getRegion('second');
    this.secondView = new CurrencyInput();
    region.show(this.secondView);
  },

  renderKeyboard() {
    const region = this.getRegion('keypad');
    const view = new KeypadView();
    region.show(view);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/fromToAmountPopup',
    });

    this.openPopup();

    return def;
  },

  applyClicked() {
    let first = this.firstView.getValue();
    let second = this.secondView.getValue();

    first = parseFloat(first) > 0 ? first : null;
    second = parseFloat(second) > 0 ? second : null;

    // If first is bigger then the second one, we swap them arround.
    if (parseFloat(first) > parseFloat(second)) {
      const tmp = first;
      first = second;
      second = tmp;
    }

    this.closeDef.resolve({
      from: first,
      to: second,
    });
    this.close();
  },

  closeClicked: _.debounce(function () {
    this.currentTarget = null;
    this.close();
  }, 10),

}));
