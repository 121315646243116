define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/giftcardManagePopup.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/toaster',

  './giftcardManagePopup/table',
  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/views/inputs/giftCardInput',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/collections/upx/PaymentProvider',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/common/collections/DeepModelCollection',

  'upx.modules/PaymentModule/models/GiftCard',
  'upx.modules/PaymentModule/models/ExternalGiftCard',
], (
  $, _, Backbone, PopupView, Template,
  Locale, CashRegisterApi, Upx, Toaster,
  TableView, KeypadsView, GiftCardInputView,
  Loader, PaymentMethods,
  PaymentProviderCollection, CurrentPaymentMethodItemCollection, DeepModelCollection,
  GiftCardModel, ExternalGiftCardModel,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--giftcard-manage',

  ui: {
    close: '[data-action="close"]',
    apply: '[data-action="apply"]',
    message: '[data-ui="message"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.apply': 'applyClicked',
    'touchend @ui.apply': 'applyClicked',
  },

  regions: {
    code: '[data-region="code"]',
    pin: '[data-region="pin"]',
    table: '[data-region="table"]',
    keys: '[data-region="keys"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ collection }) {
    PopupView.prototype.initialize.apply(this, arguments);
    const paymentCollection = this.paymentCollection = new DeepModelCollection();
    this.collection = collection || CurrentPaymentMethodItemCollection;

    this.collection.each((model) => {
      const id = model.get('id');
      if (
        id.startsWith(this.collection.GIFTCARD_METHOD)
        || id.startsWith(this.collection.EXTERNAL_GIFTCARD_METHOD)
      ) {
        paymentCollection.add(model.get('original_data'));
      }
    });
  },

  onRender() {
    this.renderCode();
    this.renderPin();
    this.renderTable();
    this.renderKeys();
  },

  renderCode() {
    this.codeView = new GiftCardInputView({
      autoSelect: true,
    });
    this.codeView.on('view:confirm', this.addGiftcard, this);
    this.getRegion('code').show(this.codeView);
  },

  renderPin() {
    this.pinView = new GiftCardInputView({
      inputDisabled: true,
    });
    this.getRegion('pin').show(this.pinView);
  },

  showLoader() {
    this.loaderDef = this.loader.startLoader();
  },

  hideLoader() {
    if (this.loaderDef) this.loaderDef.resolve();
    this.loaderDef = null;
  },

  addGiftcard(code) {
    if (!code) return; // Nothing to check

    this.codeView.disableInput();

    // Handle def and loader
    this.showLoader();
    const def = new $.Deferred();
    def.fail((resp) => {
      let error = Locale.translate('no_gift_card_found');
      if (resp && 'error' in resp) {
        error = resp.error;
      }
      Toaster.error(error);
    });
    def.always(() => {
      this.hideLoader();
      this.codeView.enableInput();
      this.codeView.reset();
      this.pinView.reset();
    });

    this.addInternalGiftcard(code)
      .then(def.resolve, (resp) => {
        this.addExternalGiftcard(code)
          .then(def.resolve, def.reject);
      });
  },

  addInternalGiftcard(code) {
    const def = new $.Deferred();

    const giftCard = new GiftCardModel();
    giftCard.find({ code })
      .then((resp) => {
        const model = new Backbone.DeepModel(_.extend({}, resp, {
          type: PaymentMethods.GIFTCARD_METHOD,
          gift_card_id: resp.id,
          id: `${PaymentMethods.GIFTCARD_METHOD}:${code}`,
        }));
        this.paymentCollection.add(model);
        def.resolve();
      }, def.reject);

    return def;
  },

  askForPin() {
    const def = new $.Deferred();

    this.hideLoader();
    def.then(() => this.showLoader());

    this.pinView.enableInput();
    this.pinView.setFocus();

    this.pinView.on('view:cancel', () => {
      this.pinView.disableInput();
      def.reject({
        error: Locale.translate('no_gift_card_added'),
      });
    });

    this.pinView.on('view:confirm', (value) => {
      this.pinView.reset();
      this.pinView.disableInput();
      def.resolve(value.trim());
    }, def.reject);

    return def;
  },

  addExternalGiftcard(code) {
    const def = new $.Deferred();

    const externalCard = new ExternalGiftCardModel();
    const upxPayModel = PaymentProviderCollection.getByTypeAlias(
      PaymentProviderCollection.TYPE_ALIAS_UPXPAY,
    );

    if (upxPayModel) {
      const cardExists = () => {
        const def = new $.Deferred();

        externalCard.find({ code, provider_id: upxPayModel.get('id') })
          .then(
            // card exists
            (cardExistsData) => def.resolve(cardExistsData),
            (resp) => {
              if (resp.class === 'PaymentModule::InvalidPinCode') {
                // card exists
                def.resolve();
              } else {
                // card does not exists
                def.reject();
              }
            },
          );

        return def;
      };

      /**
                 * @param cardExistsData {Object}
                 */
      const fetchCardWithPin = (cardExistsData = null) => {
        const def = new $.Deferred();

        this.askForPin()
          .then((pin) => {
            // If we have the existing card data here. It means we can not check the PIN the backend
            // Thus we can resolve here with the given card data.
            if (cardExistsData) {
              def.resolve(cardExistsData, pin);
            } else {
              const externalCard = new ExternalGiftCardModel();
              externalCard.find({ code, pin, provider_id: upxPayModel.get('id') })
                .then((cardData) => def.resolve(cardData, pin), (resp) => {
                  if (resp.class === 'PaymentModule::InvalidPinCode') {
                    Toaster.error(Locale.translate('incorrect_pin'));
                    fetchCardWithPin()
                      .then(def.resolve, def.reject);
                  } else if (resp.class === 'PaymentModule::InvalidGiftCard') {
                    def.reject(); // No gift card found
                  } else {
                    def.reject(resp);
                  }
                });
            }
          }, def.reject);

        return def;
      };
      cardExists()
        .then((cardExistsData) => {
          fetchCardWithPin(cardExistsData)
            .then((resp, pin) => {
              const model = new Backbone.DeepModel(_.extend({}, resp, {
                id: `${PaymentMethods.EXTERNAL_GIFTCARD_METHOD}:${code}`,
                type: PaymentMethods.EXTERNAL_GIFTCARD_METHOD,
                pin,
              }));
              this.paymentCollection.add(model);
              def.resolve();
            }, def.reject);
        }, () => def.reject()); // gift card not found
    } else {
      def.reject();
    }

    return def;
  },

  renderTable() {
    this.getRegion('table').show(new TableView({
      collection: this.paymentCollection,
    }));
  },

  renderKeys() {
    this.getRegion('keys').show(new KeypadsView());
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/giftcardManagePopup',
    });

    this.openPopup();

    return def;
  },

  applyClicked: _.debounce(function () {
    let hasMissingRequiredPin = false;
    this.paymentCollection.each((model) => {
      if (!model.get('pin') && model.get('requiresPin')) {
        hasMissingRequiredPin = true;
      }
    });

    if (!hasMissingRequiredPin) {
      this.closeDef.resolve({
        collection: this.paymentCollection,
      });
      this.close();
    } else {
      Toaster.error(Locale.translate('one_or_more_cards_are_missing_the_pincode_dot'));
    }
  }, 100),

}));
