define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/length.hbs',

  'modules/common/components/moment',
], (
  $, _, Backbone, Template,
  Moment,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  modelEvents: {
    'change:item.date_start': 'render',
    'change:item.date_end': 'render',
  },

  serializeData() {
    const startDate = new Moment(this.model.get('item.date_start'));
    const endDate = new Moment(this.model.get('item.date_end'));
    const minutesDifference = endDate.diff(startDate, 'minutes');
    return {
      hours: parseInt(minutesDifference / 60),
      minutes: minutesDifference % 60,
    };
  },

}));
