define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/pointOfSalePopup',

  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/models/settings/terminal',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/settings/shopPos',

  'modules/shop.cash-register-retail/models/shopPos',
  'modules/shop.cash-register-retail/models/customerScreenData',

  'modules/admin/behaviors/loader',

  'modules/shop.common/components/deviceConfig',
  'modules/common/components/locale',
  'modules/shop.common/components/mode',

  'modules/shop.cash-register-retail/views/inputs/selectInput',
  'modules/shop.cash-register-retail/views/posSettings/webReceiptButton',
  'modules/shop.cash-register-retail/views/posSettings/receiptPrinterSetting',
], (
  $, _, Backbone, Template,
  DeepModelCollection,
  PointOfSaleSetting, PriceTagPrinterSetting, PaymentMethodsSetting, TerminalSetting, ReceiptPrinterSetting, ShopPosSetting,
  ShopPosModel, CustomerScreenDataModel,
  Loader,
  DeviceConfig, Locale, ModeComponent,
  SelectInput, WebReceiptButton, ReceiptPrinterSettingView
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'pos-setup',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
      name: 'pos-setup',
    },
  },

  initialize() {
    this.salesChannelCollection = new DeepModelCollection();
    this.receiptPrinterCollection = new DeepModelCollection();
    this.pinTerminalCollection = new DeepModelCollection();

    this.model = new Backbone.Model({
      salesChannel: PointOfSaleSetting.get('id'),
      pinTerminal: TerminalSetting.get('id'),
      receiptPrinterId: ReceiptPrinterSetting.get('id'),
      receiptPrinterType: ReceiptPrinterSetting.get('type'),
    });
  },

  fetchCollections() {
    const def = this.loader.startLoader();

    $.when(
      PointOfSaleSetting.list(),
      PriceTagPrinterSetting.fetchPrinters(),
      PaymentMethodsSetting.load(true),
    ).then((posCollection, printerResponse) => {
      this.salesChannelCollection.add(posCollection.toJSON());
      this.pinTerminalCollection.add(PaymentMethodsSetting.getTerminalDevicesCollection().toJSON());

      const receiptPrinters = [];
      printerResponse.printers.each((printer) => {
        if (printer.get('type') === ReceiptPrinterSetting.PRINTER_TYPE_ESCPOS) {
          receiptPrinters.push(printer.toJSON());
        }
      });
      this.receiptPrinterCollection.add(receiptPrinters);

      def.resolve();
    }, def.reject);

    return def;
  },

  shopPosCache: {},
  pinCheck() {
    const shopId = this.model.get('salesChannel');
    if (shopId in this.shopPosCache) {
      this.render();
    } else {
      const def = this.loader.startLoader();

      def.then(() => this.render());

      const shopPosModel = new ShopPosModel({ shop_id: shopId });
      shopPosModel.load()
        .then(() => {
          this.shopPosCache[shopId] = shopPosModel;
          def.resolve();
        }, def.reject);
    }
  },

  onShow() {
    this.fetchCollections().then(this.render);

    this.model.on('change', this.handleChange, this);
    this.model.on('change:salesChannel', this.pinCheck, this);

    this.pinCheck();
    this.confirmCheck();
  },

  onDestroy() {
    this.model.off('change', this.handleChange, this);
    this.model.off('change:salesChannel', this.pinCheck, this);
  },

  updateReceiptPrinter() {
    const printerId = this.model.get('receiptPrinterId');
    const printerType = this.model.get('receiptPrinterType');

    if (printerId && printerType) {
      ReceiptPrinterSetting.setPrinter(printerId, printerType);
    }
  },

  updatePinTerminal() {
    const model = this.pinTerminalCollection.get(this.model.get('pinTerminal'));
    TerminalSetting.destroy();
    if (model) TerminalSetting.setTerminalByModel(model);
  },

  updateSalesChannel() {
    const model = this.salesChannelCollection.get(this.model.get('salesChannel'));

    PointOfSaleSetting.destroy();
    if (model) PointOfSaleSetting.setByModel(model);
  },

  confirmCheck() {
    this.ui.confirm.attr('disabled', !this.getShopPos());
  },

  handleChange() {
    this.updatePinTerminal();
    this.confirmCheck();
  },

  ui: {
    confirm: '[data-ui=confirm]',
  },

  events: {
    'click @ui.confirm': 'confirmClicked',
  },

  getShopPos() {
    return this.shopPosCache[this.model.get('salesChannel')];
  },

  confirmClicked() {
    const model = this.getShopPos();
    if (model) {
      this.updateSalesChannel();
      ShopPosSetting.loadFromModel(model);

      PaymentMethodsSetting.load(true).then(
        () => {
          this.triggerMethod('shop::selected');
          CustomerScreenDataModel.reloadCustomerScreen();

          this.updateReceiptPrinter();
        },
        () => {
          // failed to reload, lets reload the full app
          window.location.reload();
        },
      );
    }
  },

  hasPin() {
    const model = this.getShopPos();
    if (model) {
      const payments = model.get('payment_categories') || [];
      return payments.indexOf('pin') !== -1;
    }

    return false;
  },

  serializeData() {
    return {
      pinEnabled: this.hasPin()
    };
  },

  regions: {
    salesPoint: '[data-region=sales-point]',
    pinTerminal: '[data-region=pin-terminal]',
    receiptPrinter: '[data-region=receipt-printer]',
  },

  onRender() {
    this.renderSalesPoint();

    if (this.hasPin()) {
      this.renderPinTerminal();
    }

    if (ModeComponent.isInWebMode()) {
      this.renderWebReceiptPrinter();
    } else if(ModeComponent.isInAppMode()) {
      this.renderNetworkReceiptPrinter();
    } else {
      this.renderReceiptPrinter();
    }

    this.confirmCheck();
  },

  renderSalesPoint() {
    const region = this.getRegion('salesPoint');
    const view = new SelectInput({
      model: this.model,
      collection: this.salesChannelCollection,
      targetPath: 'salesChannel',
      isValueInt: true,
      emptyLabel: Locale.translate('select_a_point_of_sale'),
      labelPath: 'name',
      presetFirstIfOneOption: true,
    });
    region.show(view);
  },

  renderPinTerminal() {
    const region = this.getRegion('pinTerminal');
    const view = new SelectInput({
      model: this.model,
      collection: this.pinTerminalCollection,
      targetPath: 'pinTerminal',
      emptyLabel: Locale.translate('select_a_pin_terminal'),
      labelPath: 'title',
      presetFirstIfOneOption: true,
      isValueInt: true,
    });
    region.show(view);
  },

  renderReceiptPrinter() {
    const region = this.getRegion('receiptPrinter');

    const printers = this.receiptPrinterCollection.toJSON()
      .map(p => ({id: p.id, label: p.name}))

    const view = new ReceiptPrinterSettingView({
      model: this.model,
      targetPathId: 'receiptPrinterId',
      targetPathType: 'receiptPrinterType',
      printers,
      updateInModel: true,
      setFirstOptionInModel: true
    });

    region.show(view);
  },

  renderWebReceiptPrinter() {
    const region = this.getRegion('receiptPrinter');
    const view = new WebReceiptButton({
      model: this.model,
      targetPath: 'receiptPrinterId',
      emptyLabel: Locale.translate('select_a_receipt_printer'),
      triggerUpdateInModel: true,
    });
    region.show(view);
  },

  renderNetworkReceiptPrinter() {
    const region = this.getRegion('receiptPrinter');
    const view = new ReceiptPrinterSettingView({
      model: this.model,
      targetPathId: 'receiptPrinterId',
      targetPathType: 'receiptPrinterType',
      availableTypes: ['network'],
      updateInModel: true,
      setFirstOptionInModel: true
    });
    region.show(view);
  },

}));
