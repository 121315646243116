define([
  'jquery',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/customerOrderListPopup.hbs',
  'modules/common/components/locale',

  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/views/orders/overview/collection',

  'upx.modules/ShopModule/collections/Order',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, Backbone, PopupView, Template, Locale,
  Loader,
  OverviewCollectionView,
  OrderCollection,
  CashRegisterApi,

) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  regions: {
    overview: '[data-region="overview"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  childEvents: {
    'receipt:selected': 'receiptSelected',
  },

  receiptSelected(view, model) {
    this.closeDef.resolve({
      orderModel: model,
    });
    this.close();
  },

  initialize() {
    this.collection = new OrderCollection();
    this.on('loader:start', function (def, name) {
      this.loader.showLoader(name);
      def.always(() => {
        this.loader.hideLoader(name);
      });
    });
  },

  modelChanged() {
    const self = this;
    this.collection.reset();

    const filters = [{
      name: 'relation_data_id__=',
      val: this.model.get('id'),
    }, {
      name: 'is_quote__=',
      val: '0',
    }, {
      name: 'is_contract__=',
      val: '0',
    }, {
      name: 'is_request__=',
      val: '0',
    }, {
      name: 'status__not_in_list',
      multi_val: ['cancelled', 'concept'],
    }];

    const parameters = {
      start: 0,
      limit: 20,
      sort: [{
        name: 'date_created',
        dir: 'desc',
      }],
      filters,
    };
    const def = this.collection.fetch({ params: parameters })
      .then(() => {
        if (self.model.has('number') && self.collection.length === 1) {
          const model = self.collection.first();
          if (model) {
            self.openReturnProductsPopup(model);
          }
        }
      }, () => {
        self.collection.reset();
      });
    this.triggerMethod('loader:start', def, 'overview');
  },

  renderOverview() {
    this.modelChanged();
    const region = this.getRegion('overview');

    const view = new OverviewCollectionView({
      collection: this.collection,
    });

    region.show(view);
  },

  onRender() {
    this.renderOverview();
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/customerOrderListPopup',
    });

    this.openPopup();

    return def;
  },

}));
