define([
  'jquery',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/customersPopup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/views/customers/list/collection',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, PopupView, Template, Locale, CollectionView, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    message: '[data-ui="message"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  regions: {
    list: '[data-region="list"]',
  },

  open(customerCollection, def) {
    def = this.renderInFloatingRegion(def);

    const region = this.getRegion('list');
    const view = new CollectionView({
      collection: customerCollection,
      resultDef: def,
    });
    region.show(view);

    def.always(() => this.close());

    view.on('start:loader', () => {
      this.loader = this.startLoader();
    });

    view.on('stop:loader', () => {
      this.loader.resolve();
    });

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/customersPopup',
    });

    this.openPopup();
  },

}));
