define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/sum/duration.hbs',

  'toastr',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template,
  Toastr, Locale,
  Keyboard,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'sum__item',

  events: {
    'click [data-action="save-duration"]': 'saveClicked',
    'keyup [data-action="duration"]': 'durationChanged',
  },

  initialize() {
    this.duration_in_minutes = this.model.get('extra.duration_in_minutes');
  },

  saveClicked() {
    if (!this.duration_in_minutes) {
      Toastr.error(Locale.translate('appointment_duration_can_not_be_empty'));
      return;
    }

    this.model.unset('edit');
    this.model.set('extra.duration_in_minutes', this.duration_in_minutes);
    this.model.set('extra.duration_in_seconds', this.duration_in_minutes * 60);

    this.triggerMethod('layout:swap');
  },

  onShow() {
    this.model.set('edit', true);
    Keyboard.setViewWithMode(this.cid, 'event_duration');
    Keyboard.on('key:confirm', () => {
      if (Keyboard.isViewAndMode(this.cid, 'event_duration')) {
        this.saveClicked();
      }
    });
  },

  durationChanged(ev) {
    const el = ev.currentTarget;
    let value = el.value.trim();

    if (value === '') {
      this.duration_in_minutes = null;
    } else {
      value = parseInt(el.value);
      if (_.isNaN(value)) value = this.duration_in_minutes;

      el.value = value;
      this.duration_in_minutes = value;
    }
  },
}));
