define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/paymentErrorPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/views/payments/processingCollection',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi, ProcessingCollectionView,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--message paymentErrorPopup',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  regions: {
    results: '[data-region="results"]',
  },

  getErrorMessage(error) {
    let errMessage = Locale.translate('payment_failed');

    if (typeof error === 'string') {
      errMessage = error;
    } else if (typeof error === 'object') {
      if (typeof error.refundError === 'object' && typeof error.refundError.error === 'string') {
        errMessage = error.refundError.error;
      } else if (typeof error.error === 'string') {
        errMessage = error.error;
      }
    }

    return errMessage;
  },

  open(error, number, collection, type) {
    const def = this.renderInFloatingRegion();
    const errMessage = this.getErrorMessage(error);
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/paymentErrorPopup',
      error: errMessage,
    });
    this.ui.title.text(errMessage);

    this.getRegion('results').show(new ProcessingCollectionView({
      number, collection, type,
    }));

    this.openPopup();

    return def;
  },

}));
