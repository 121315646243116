define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/simplePopup',

  'modules/common/components/locale',
  'modules/shop.common/components/floatingRegion',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  // non imports
  'magnific-popup',
], (
  $, _, Backbone, Template,
  Locale, FloatingRegion, CashRegisterApi,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'dialog dialog--simple',

  startLoader() {
    const def = new $.Deferred();

    // Add the loader
    const $content = $('.mfp-content');
    $content.prepend('<div class="loader-wrapper"><div class="loader"></div></div>');

    // Handle deferred
    def.always(() => {
      $content.find('.loader-wrapper').remove();
    });

    return def;
  },

  defaults: {
    // Header settings
    headerText: false,
    headerCancelEnabled: true,

    // Content can be one of the following:
    //	- A string
    //	- A function returning a string
    //	- a template
    //	- A view
    content: '',

    // Confirm button
    footerConfirmEnabled: true,
    footerConfirmText: Locale.translate('confirm'),

    // Cancel button
    footerCancelEnabled: true,
    footerCancelText: Locale.translate('cancel'),
  },

  initialize(options) {
    this.options = _.extend({}, this.defaults, options);

    // Assign options to this;
    this.content = this.options.content;
  },

  ui: {
    content: '[data-ui="content"]',
  },

  regions: {
    content: '@ui.content',
  },

  renderContent() {
    const region = this.getRegion('content');
    const { content } = this;

    // Content is a string
    if (_.isString(content)) {
      this.ui.content.text(content);
    }
    // Content is a function returning a string or a template.
    else if (_.isFunction(content)) {
      region.show(new (Backbone.Marionette.ItemView.extend({
        template: this.content(),
      }))());
    }
    // Content is a View
    else if (_.isObject(content) && _.isFunction(content.render)) {
      region.show(this.content);
    } else {
      throw new Error('Cannot detect content for popup view, expected String, Function or Backbone View');
    }
  },

  onRender() {
    this.renderContent();
  },

  serializeData() {
    const opt = this.options;

    return {
      headerEnabled: !!opt.headerText || !!opt.headerCancelEnabled,
      headerText: opt.headerText,
      headerCancelEnabled: opt.headerCancelEnabled,
      footerConfirmEnabled: opt.footerConfirmEnabled,
      footerConfirmText: opt.footerConfirmText,
      footerCancelEnabled: opt.footerCancelEnabled,
      footerCancelText: opt.footerCancelText,
    };
  },

  events: {
    'click [data-action="confirm"]': 'confirmClicked',
    'click [data-action="cancel"]': 'cancelClicked',
  },

  confirmClicked() {
    this.triggerMethod('popup:confirm');
  },

  cancelClicked() {
    this.triggerMethod('popup:cancel');
  },

  // Popup functions
  open() {
    // clears all other popups
    FloatingRegion.cleanRegions((regionName) => regionName.startsWith('popup::'));

    // Render popup inm floating region
    const popupId = this.getPopupId();
    FloatingRegion.getRegion(popupId).show(this);
    this.$el.data('popupId', popupId);

    // Cach Register API
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/simplePopup',
    });

    $.magnificPopup.open({
      items: [{
        src: this.$el.get(0),
        type: 'inline',
      }],
      removalDelay: 0,
      mainClass: 'mfp-fade',
      modal: true,
    });
  },

  close() {
    if ($.magnificPopup.instance && $.magnificPopup.instance.isOpen) {
      const currentItem = $.magnificPopup.instance.currItem;
      if (currentItem.inlineElement) {
        if (currentItem.inlineElement.data('popupId') === this.getPopupId()) {
          $.magnificPopup.close();
        }
      } else {
        console.warn(`Not correctly handled magnificPopup.close [${this.getPopupId()}]`);
        $.magnificPopup.close();
      }
    }
  },

  getPopupId() {
    return `popup::${this.cid}`;
  },

  onDestroy() {
    FloatingRegion.cleanRegion(this.getPopupId());
  },

}));
