define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/dateTime.hbs',

  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/dateTimePicker',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.common/components/onScreenKeyboard',
], (
  $, _, Backbone, Template,
  Moment, DateTimePicker,
  Keyboard, OnScreenKeyboard,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  modelEvents: {
    'change:item.date_start': 'render',
  },

  ui: {
    time: '[data-ui=time]',
  },

  events: {
    'click [data-action=date]': 'dateClicked',
    'touchend [data-action=date]': 'dateClicked',

    'click [data-ui="time"]': 'clearTime',
    'touchend [data-ui="time"]': 'clearTime',

    'keyup [data-ui="time"]': 'timeChanged',
  },

  onShow() {
    Keyboard.on('key:confirm', this.confirmTime, this);
    Keyboard.on('key:backspace', this.clearTime, this);
    Keyboard.on('change:viewId', this.resetTime, this);
    this.interval = setInterval(() => {
      if (Keyboard.isNeutralMode()) Keyboard.setViewWithMode(this.cid, Keyboard.MODE_EVENT_TIME_MODE);
    }, 100);

    // Check if the keyboard state changes
    this.keyboardShown = false;
    this.keyboardInterval = setInterval(() => {
      if (!this.keyboardShown && OnScreenKeyboard.isShown()) {
        this.keyboardShown = true;
      } else if (this.keyboardShown && !OnScreenKeyboard.isShown()) {
        this.keyboardShown = false;
        this.confirmTime();
      }
    }, 100);
  },

  onDestroy() {
    Keyboard.off('key:confirm', this.confirmTime, this);
    Keyboard.off('key:backspace', this.clearTime, this);
    Keyboard.off('change:viewId', this.resetTime, this);
    clearInterval(this.interval);
    clearInterval(this.keyboardInterval);
    Keyboard.resetMode();
  },

  resetTime() {
    this.ui.time.val(
      new Moment(this.model.get('item.date_start')).format('HH:mm'),
    );
  },

  isMyMode() {
    return Keyboard.isViewAndMode(this.cid, Keyboard.MODE_EVENT_TIME_MODE);
  },

  clearTime() {
    if (this.isMyMode()) {
      this.ui.time.val('');
    }
  },

  /**
		 * This function get the time input and formats to to a valid time;
		 */
  confirmTime() {
    if (this.isMyMode()) {
      const el = this.ui.time.get(0);
      const value = el.value.trim();

      const date = new Moment(this.model.get('item.date_start'));
      let hour = date.format('HH');
      let minute = date.format('mm');

      // length ranged 3 - 5 & Does contains a :
      if (
        value.length >= 3
					&& value.length <= 5
					&& value.indexOf(':') !== -1
      ) {
        const split = value.split(':');
        hour = split[0] ? split[0] : hour;
        minute = split[1] ? split[1] : minute;
      } else
      // length 4 & Does NOT contains a :
      if (
        value.length === 4
					&& value.indexOf(':') === -1
      ) {
        hour = value.substr(0, 2);
        minute = value.substr(2, 2);
      } else
      // length 3 && Does NOT contains a :
      if (
        value.length === 3
					&& value.indexOf(':') === -1
      ) {
        hour = value.substr(0, 1);
        minute = value.substr(1, 2);
      }

      // format hour
      if (hour.length < 2) hour = `0${hour}`;

      // Format minute
      if (minute.length < 2) minute = `0${minute}`;

      // hour check
      if (parseInt(hour) > 23) hour = 23;

      // minute check
      if (parseInt(minute) > 59) minute = 59;

      // update inpuit
      el.value = `${hour}:${minute}`;

      // Update model;
      date
        .hour(hour)
        .minute(minute);
      this.model.set({ 'item.date_start': date.format() });
      this.model.calculateEndDate();
    }
  },

  timeChanged(ev) {
    const el = ev.currentTarget;
    const value = el.value.trim();

    if (value.length > 5) {
      el.value = value.substr(0, 5);
    }
  },

  dateClicked: _.debounce(function (ev) {
    ev.currentTarget.blur();

    DateTimePicker.datePicker({ value: this.model.get('item.date_start') })
      .then((resp) => {
        const date = new Moment(this.model.get('item.date_start'));
        date
          .year(resp.year)
          .month(resp.month - 1) // There is a month offset
          .date(resp.day);
        this.model.set({ 'item.date_start': date.format() });
        this.model.calculateEndDate();
      });
  }, 100),

  serializeData() {
    return {
      date: new Moment(this.model.get('item.date_start')).format('DD MMMM YYYY'),
      time: new Moment(this.model.get('item.date_start')).format('HH:mm'),
    };
  },

}));
