define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/hospitalityMembercardPopup.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  './hospitalityMembercardPopup/swappable',
], (
  $, _, Backbone, PopupView, Template,
  Locale, CashRegisterApi,
  SwappableView,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--hospitality-membercard',

  ui: {
    customer: '[data-ui=customer]',
    title: '[data-ui=title]',
  },

  events: {
    // close
    'click [data-action="close"]': 'closeClicked',
    'touchend [data-action="close"]': 'closeClicked',
  },

  regions: {
    swappable: '[data-region="swappable"]',
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
  },

  renderContent() {
    const region = this.getRegion('swappable');
    const view = new SwappableView({
      closeDef: this.closeDef,
    });
    region.show(view);

    // When the closeDef is being resolve/reject. we kill the swappable to cleanup the views
    this.closeDef.always(() => {
      view.destroy();
    });
  },

  open(def, title) {
    title = title || Locale.translate('scan_new_customer_card');
    def = this.renderInFloatingRegion(def);
    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/hospitalityMembercardPopup',
      title,
    });
    $.magnificPopup.open({
      items: [{
        src: this.$el.get(0),
        type: 'inline',
      }],
      removalDelay: 0,
      mainClass: 'mfp-fade',
      modal: true,
    });

    this.renderContent();

    return def;
  },

}));
